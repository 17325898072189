<template>
    <transition name="component-fade" mode="out-in">
        <div class="ui grid">
            <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
            <div class="two column row" id="audiovisual">
                <div class="fourteen wide computer fourteen wide tablet fourteen wide phone column">
                    <img src="@/assets/images/av1.png">
                </div>
            </div>
            <div class="two column row">
                <div class="eight wide computer fourteen wide tablet fourteen wide phone column">
                    <h4>Ruggles station's</h4>
                    <h4>​Audio–Video Transparency</h4>
                    <h4>For Northeastern University – Information Design + Visualization</h4>
                    <p>As visualization developer</p>
                    <p>
                        Link to <a href="https://www.youtube.com/watch?v=66c8bsmyDOk&feature=emb_logo"  target="_blank">
                        video
                    </a>
                    </p>
                    <p>{{p1}}</p>
                </div>
                <div class="six wide computer fourteen wide tablet column">
                </div>
            </div>
            <div class="one column row">
                <div class="seven wide computer fourteen wide tablet column">
                </div>
                <div class="seven wide computer fourteen wide tablet column">
                </div>
            </div>
            <div class="one column row">
                <div class="seven wide computer fourteen wide tablet column"></div>
                <iframe width=80% height="515" src="https://www.youtube.com/embed/66c8bsmyDOk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "AudiovisualView",
        data(){
            return {
            p1: "Inside and outside of frame\n" +
            "of attention.\n" +
            "Exploring the information\n" +
            "​transmitted through sound. \n" +
            "using the processing application\n" +
            "for reading video and audio recording files\n" +
            "visualizing the sound using Minim library."}
        },
        computed:{
            mode: function () {
                let self = this;
                return self.$parent.mode
            }
        }
    }
</script>
<style scoped>
</style>