<template>
    <transition name="component-fade" mode="out-in">
        <div class="ui grid">
            <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
            <div class="two column row" id="remembrance">
                <div class="fourteen wide computer fourteen wide tablet fourteen wide phone column">
                    <img src="@/assets/images/remembrance4.jpg">
                </div>
            </div>
            <div class="two column row">
                <div class="eight wide computer fourteen wide tablet fourteen wide phone column">
                    <h4>Remembrance of the Climate future</h4>
                    <h4>Web application</h4>
                    <h5>For College of Arts, Media, and Design at Northeastern</h5>
                    <p>As visualization developer</p>
                    <p>
                        Read more about this project <a href="https://www.untoldpossibilities.org/remembranceofclimatefutures" target="_blank">here.</a>

                    </p><p>
                        Link to <a href="https://seacoast.climatefutures.us/#/durham-1"  target="_blank">
                        Durham's implementation</a>.
                    </p>
                    <p>{{p1}}</p>
                    <p>{{p2}}</p>

                </div>
                <div class="six wide computer fourteen wide tablet column">
                    <img src="@/assets/images/remembrance2.jpg">
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "RemembranceView",
        data(){
            return {

                p1: "Web app for\n" +
                    "returning associated info that is pointed from " +
                    "embedded QR codes printed on urban installations. The project\n" +
                    "was installed in Cambridge, Durham, and Essex MA. \n" +
                    "Developing with JavaScript-- Vuejs, d3js | NGINX | GCP."
            }
        },
        computed:{
            mode: function () {
                let self = this;
                return self.$parent.mode
            }
        }
    }
</script>
<style scoped>
</style>