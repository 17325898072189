<template>
    <transition name="component-fade" mode="out-in">
        <div class="ui grid">
            <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
            <div class="two column row" id="grandjunction">
                <div class="fourteen wide computer fourteen wide tablet fourteen wide phone column">
                    <img src="@/assets/images/gj11.png">
                </div>
            </div>
            <div class="two column row">
                <div class="eight wide computer fourteen wide tablet fourteen wide phone column">
                    <h4>Cambridge Grand Junction Pathway Scenario Modeling</h4>
                    <h4>For the Metropolitan Area Planning Council</h4>
                    <p>As analyst and developer</p>
                    <p>
                        Link to analysis <a href="https://github.com/MAPC/Grand-Junction-Pathway-Benefits"  target="_blank">
                        code repository
                    </a>  on GitHub
                    </p>
                    <p>
                        Link to <a href="https://www.sciencedirect.com/science/article/pii/S1361920920305757" target="_blank">
                        publication
                    </a>
                    </p>
                    <p>{{p1}}</p>
                    <p>{{p2}}</p>
                </div>
                <div class="six wide computer fourteen wide tablet column">
                    <expandable-image
                            :src="require('@/assets/images/gj2.png')"
                    />
                </div>

            </div>
            <div class="one column row">
                <div class="seven wide computer fourteen wide tablet column">
                </div>
                <div class="seven wide computer fourteen wide tablet column">
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "GandjunctionView",
        data(){
            return {
                p1:"Cycling network analysis; Developing routing algorithms, visualization techniques; researching cycling\n" +
                    "route choice using GPS data; Cycling stress-level analysis with OpenStreetMap data. Developing with\n" +
                    "Java | JavaScript-- d3js,mapboxgl | Python | Postgresql."
            }
        },
        computed:{
            mode: function () {
                let self = this;
                return self.$parent.mode
            }

        }
    }
</script>

<style scoped>

</style>