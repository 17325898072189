import { render, staticRenderFns } from "./AudiovisualView.vue?vue&type=template&id=61b210b5&scoped=true"
import script from "./AudiovisualView.vue?vue&type=script&lang=js"
export * from "./AudiovisualView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b210b5",
  null
  
)

export default component.exports