<template>
    <transition name="component-fade" mode="out-in">
        <div class="ui grid">
            <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
            <div class="two column row" id="landparcels">
                <div class="fourteen wide computer fourteen wide tablet fourteen wide phone column">
                    <expandable-image
                            :src="require('@/assets/images/lp2.png')"
                    />
                </div>
            </div>
            <div class="two column row">
                <div class="eight wide computer fourteen wide tablet fourteen wide phone column">
                    <h4>The Massachusetts Land Parcel Database</h4>
                    <h4>More than 2.1 million land parcel boundaries and associated tax assessor data.</h4>
                    <h5>For  Metropolitan Area Planning Council </h5>
                    <p>As front-end developer</p>
                    <p>
                        Available at <a href="https://www.mapc.org/learn/data/" target="_blank">
                        MAPC's website
                    </a>
                    </p>
                    <p>{{p2}}</p>
                </div>
                <div class="six wide computer fourteen wide tablet column">
                    <img src="@/assets/images/lp1.png">
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "LandparcelsView",
        data() {
            return {
                p2: "The Massachusetts Land Parcel Database is a statewide atlas of more than 2.1 million\n" +
                    " land parcel boundaries and associated tax assessor data. Working from digital parcel\n" +
                    " and assessor records created and/or standardized by MassGIS (the Commonwealth’s Office\n" +
                    " of Geographic Information), MAPC compiled data previously available only on a\n" +
                    " town-by-town basis in each of the state’s 351 municipalities. In addition, we calculated\n" +
                    " additional statistics about each parcel, including floor area ratio, assessed\n" +
                    " value per area, impervious surface, and other indicators. This dataset is a unique \n" +
                    "resource for planners, researchers, advocates, and taxpayers interested in land use, urban\n" +
                    " form, development opportunities, and tax assessment across the state.\n" +
                    "The dataset is used in various projects at MAPC since it was made, including multiple buildout\n" +
                    " and scenario modeling analysis, various land use and economic development projects. This data \n" +
                    "sets also introduced new data points that were not available before on a state-wide scale, \n" +
                    "which resulted in better analysis and planning in many projects, such as floor area ratio (FAR)\n" +
                    ", tax revenue and property value per acres, and improvement to land value ratio.",
                p1: "A UNIQUE RESOURCE FOR PLANNERS, RESEARCHERS, ADVOCATES, AND TAXPAYERS INTERESTED IN LAND USE,\n" +
                    " URBAN FORM, DEVELOPMENT OPPORTUNITIES, AND TAX ASSESSMENT ACROSS THE STATE.\n"
            };
        },
        computed:{
            mode: function () {
                let self = this;
                return self.$parent.mode
            }
        }
    }
</script>
<style scoped>
</style>