<template>
    <transition name="component-fade" mode="out-in">
        <div class="ui grid">
            <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
            <div class="two column row" id="indicators">
                <div class="fourteen wide computer fourteen wide tablet fourteen wide phone column">

                </div>

            </div>

            <div class="two column row">
                <div class="eight wide computer fourteen wide tablet fourteen wide phone column">
                    <h4>Transportation Indicators | MAPC's Regional Indicators</h4>
                    <h4>Web visualization</h4>

                    <h5>For Dukakis Center for Urban and Regional Policy and Metropolitan Area Planning Council</h5>
                    <p>As visualization developer</p>
                    <p>
                        Link to <a href="http://www.regionalindicators.org/topic_areas/2"  target="_blank">transportaion indicator's page</a>,
                    </p>
                    <p>{{p1}}</p>
                    <p>{{p2}}</p>
                </div>
                <div class="six wide computer fourteen wide tablet column">
                    <img src="@/assets/images/ti1.png">
                    <img src="@/assets/images/ti2.png">
                    <img src="@/assets/images/ti3.png">
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "IndicatorsView",
        data(){
            return {
                p1: "A set of interactive web visualizations was created using C3js, a library built on top of D3js from the data points provided by the Dukakis Center and MAPC's research teams as part of Metro Boston's Regional Indicators project. The code and visuals are used by MAPC in the final publications and in the website launched in Jan 2017."
            }
        },
        computed:{
            mode: function () {
                let self = this;
                return self.$parent.mode
            }

        }
    }
</script>

<style scoped>

</style>