<template>
    <transition name="component-fade" mode="out-in">
        <div class="ui grid">
            <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
            <div class="two column row" id="tstation">
                <div class="fourteen wide computer fourteen wide tablet fourteen wide phone column">
                    <img src="@/assets/images/tstation1.png">
                </div>
            </div>
            <div class="two column row">
                <div class="eight wide computer fourteen wide tablet fourteen wide phone column">
                    <h4>Information Station </h4>
                    <h4>website and data potal</h4>
                    <h5>For Metropolitan Area Planning Council + Dukakis Center for Urban and Regional Policy</h5>
                    <p>As front-end developer</p>
                    <p>
                        Live at <a href="http://www.tstation.info/#/"  target="_blank">
                        www.tstation.info
                    </a>
                    </p>
                    <p>{{p1}}</p>
                    <p>{{p2}}</p>
                </div>
                <div class="six wide computer fourteen wide tablet column">
                    <expandable-image
                            :src="require('@/assets/images/tstation2.jpg')"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "TstationView",
        data(){
            return {
                p1: "MAPC's first one-page app. Developing with JavaScript-- Backbonejs, leaflletjs | Ruby on Rails."
            }
        },
        computed:{
            mode: function () {
                let self = this;
                return self.$parent.mode
            }
        }
    }
</script>
<style scoped>
</style>