<template>
    <transition name="component-fade" mode="out-in">
        <div class="ui grid">
            <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
            <div class="two column row" id="firstmiles">
                <div class="fourteen wide computer fourteen wide tablet fourteen wide phone column">
                    <img src="@/assets/images/fm3.png">
                </div>
            </div>
            <div class="two column row">
                <div class="eight wide computer fourteen wide tablet fourteen wide phone column">
                    <h4>First Miles</h4>
                    <h4>For the Metropolitan Area Planning Council</h4>
                    <p></p>
                    <p>
                        Live: <a href="http://mapc.ma/firstmiles" target="_blank">
                        http://mapc.ma/firstmiles
                    </a>
                    </p>
                    <p>{{p1}}</p>
                    <p>{{p2}}</p>
                </div>
                <div class="six wide computer fourteen wide tablet column">
                    <expandable-image
                            :src="require('@/assets/images/fm4.png')"
                    />
                    <expandable-image
                            :src="require('@/assets/images/fm2.png')"
                    />
                </div>
            </div>
            <div class="one column row">
                <div class="seven wide computer fourteen wide tablet column">
                </div>
                <div class="seven wide computer fourteen wide tablet column">
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "FirstmilesView",
        data(){
            return {
                p1: "First Miles: Examining 18 Months of Dockless Bikeshare in \n" +
                    "Metro Boston is a research brief from the\n" +
                    "Metropolitan Area Planning Council. November 7, 2019; \n" +
                    "As transportation research associate and developer, contributed with developing data\n" +
                    "processing pipeline including map-matching algorithms. Developing with Python | Java | Nodejs |\n" +
                    "Docker | Postgres PostGIS."
            }
        },
        computed:{
            mode: function () {
                let self = this;
                return self.$parent.mode
            }
        }
    }
</script>
<style scoped>
</style>