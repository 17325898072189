<template>
    <transition name="component-fade" mode="out-in">
        <div class="ui grid">
            <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
            <div class="two column row" id="conceptmaps">
                <div class="fourteen wide computer fourteen wide tablet fourteen wide phone column">
                    <expandable-image
                            :src="require('@/assets/images/cm5.png')"
                    />
                </div>
            </div>
            <div class="two column row">
                <div class="three wide computer fourteen wide tablet fourteen wide phone column">
                    <h4>Concept maps</h4>
                    <h5></h5>
                    <p></p>
                    <p>
                    </p>
                    <p>{{p1}}</p>
                    <p>{{p2}}</p>
                </div>
                <div class="eleven wide computer fourteen wide tablet column">
                    <expandable-image
                            :src="require('@/assets/images/cm1.png')"
                    />
                    <expandable-image
                            :src="require('@/assets/images/cm2.png')"
                    />
                    <expandable-image
                            :src="require('@/assets/images/cm4.png')"
                    />
                    <expandable-image
                            :src="require('@/assets/images/cm6.png')"
                    />
                    <expandable-image
                            :src="require('@/assets/images/cm7.png')"
                    />
                    <expandable-image
                            :src="require('@/assets/images/cm8.png')"
                    />
                    <expandable-image
                            :src="require('@/assets/images/cm3.png')"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "ConceptmapsView",
        computed:{
            mode: function () {
                let self = this;
                return self.$parent.mode
            }
        }
    }
</script>
<style scoped>
</style>