<template>
  <transition name="component-ease" mode="out-in">
    <div class="ui grid">
      <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
      <div class="two column row" id="about">
        <div class="fourteen wide computer fourteen wide tablet fourteen wide phone column">

        </div>

      </div>

      <div class="two column row">
        <div class="eight wide computer fourteen wide tablet fourteen wide phone column">

          <p>{{p1}}</p>

        </div>
        <div class="six wide computer fourteen wide tablet column">


        </div>

      </div>

    </div>


  </transition>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: 'about',
    data: function(){
      return {
      p1:"I am a designer, developer, engineer, policy analyst and city planner using IT-enabled-interdisciplinary " +
          "design methods to produce knowledge to be used" +
          " as arguments for planning and policy making. " +
          "I Worked for the Metropolitan Area Planning Council (MAPC), MIT’s Center for Advanced Urbanism, " +
          "MIT’s Civic Data Design Lab, Dukakis Center for Urban and Regional Policy and Boston Area Research " +
          "Initiative. I am Ph.D. candidate in Interdisciplinary Engineering, " +
          "MFA in Information Design and Visualization and MS in Urban and Regional Policy from Northeastern University, " +
          "and BA in Urban Planning from University of Tehran."  }
    },
    computed:{
      mode: function () {
        let self = this;
        return self.$parent.mode
      }

    }

  }
</script>
